import { createSlice } from "@reduxjs/toolkit";
import { getBlogsApi } from "../../api/blogs";

export const slice = createSlice({
  name: "singleblog",
  initialState: {
    SingleBlog: {},
    SingleBlogLoading: false,
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    SingleBlogSuccess: (state, action) => {
      state.SingleBlog = action.payload;
      state.SingleBlogLoading = false;
      state.error.haveError = false;
    },
    SingleBlogFailed: (state, action) => {
      state.SingleBlog = {};
      state.SingleBlogLoading = false;
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
  },
});

export const selectSingleBlog = (state) => state.singleblog.SingleBlog || {};
export const selectSingleBlogLoading = (state) =>
  state.Singleblog.singleBlogLoading || false;
export const selectSingleBlogErr = (state) => state.singleblog.error || {};

export const { SingleBlogSuccess, SingleBlogFailed } = slice.actions;

export const GetSingleBlog = (dataObject) => async (dispatch) => {
  try {
    const response = await getBlogsApi(dataObject);
    dispatch(SingleBlogSuccess(response));
  } catch (err) {
    dispatch(SingleBlogFailed(err.toString()));
  }
};

export default slice.reducer;
