import { configureStore } from "@reduxjs/toolkit";
import reviewReducer from "./pages/Review/reviewSlice";
import categoryReducer from "./pages/Category/categoryslice";
import siteReducer from "./pages/Home/siteSlice";
import profileReducer from "./pages/Profile/profileSlice";
import userReviewReducer from "./pages/Profile/userReviewSlice";
import blogsReducer from "./pages/Blog/blogSlice";
import singleBlogReducer from "./pages/SingleBlog/SingleBlogSlice";
import trendingReviewReducer from "./pages/Home/trendingReviewSlice";
import categorySiteReducer from "./pages/CategoryDetail/categoryDetailSlice";
import updateReviewReducer from "./pages/UpdateReview/updateSlice";
import userSiteReducer from "./pages/Site/siteSlice";
import profileSiteReducer from "./pages/Profile/userSiteSlice";
export default configureStore({
  reducer: {
    review: reviewReducer,
    category: categoryReducer,
    site: siteReducer,
    profile: profileReducer,
    profileReview: userReviewReducer,
    blog: blogsReducer,
    singleblog: singleBlogReducer,
    trendingReview: trendingReviewReducer,
    categorySite: categorySiteReducer,
    userReview: updateReviewReducer,
    userSite: userSiteReducer,
    profileSite: profileSiteReducer,
  },
});
