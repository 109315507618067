import {
  deleteRequest,
  postRequest,
  putRequest,
  NoAuthGetRequest,
  getRequest,
} from "./request";

const user = localStorage.getItem("user");
var userData = JSON.parse(user);

export function createReviewApi(dataObject) {
  return postRequest({
    path: `review/`,
    data: {
      site: dataObject?.site,
      rating: dataObject?.rating,
      title: dataObject?.title,
      comment: dataObject?.comment,
      suggestion: dataObject?.suggestion,
      user: userData?.id,
      isSpam: dataObject.isSpam,
    },
  }).then((response) => {
    return response ? response : undefined;
  });
}

export function getTrendingReviewApi(dataObject) {
  return NoAuthGetRequest({
    path: `review/trending_reviews/`,
  }).then((response) => {
    return response ? response : undefined;
  });
}

export function getUserReviewApi(dataObject) {
  return getRequest({
    path: `review/user_review_for_site/?site_id=${dataObject.site_id}`,
  }).then((response) => {
    return response ? response : undefined;
  });
}

export function updateReviewApi(dataObject) {
  return putRequest({
    path: `review/${dataObject.id}/`,
    data: {
      site: dataObject?.site,
      rating: dataObject?.rating,
      title: dataObject?.title,
      comment: dataObject?.comment,
      suggestion: dataObject?.suggestion,
      category: dataObject?.category,
      user: userData?.id,
      isSpam: dataObject.isSpam,
    },
  }).then((response) => {
    return response ? response : undefined;
  });
}

export function deleteReviewApi(dataObject) {
  return deleteRequest({
    path: `review/${dataObject.id}/`,
  }).then((response) => {
    return response ? response : undefined;
  });
}
