import { Link, useNavigate } from "react-router-dom";

import styles from "./Header.module.css";
import logo from "../../images/wyr-logo.png";
function Header() {
  const navigate = useNavigate();

  const user = localStorage.getItem("user");
  var userData = JSON.parse(user);

  const logoutUser = () => {
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload(true);
  };

  return (
    <nav className={styles.navbar}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="thumbsup" />

        {/* <div className={styles.logo}>LOGO</div> */}
      </Link>
      <div className={styles.nav_right}>
        <div>
          <Link className={styles.navcontent} to="/blog">
            Blogs
          </Link>
          <Link className={styles.navcontent} to="/category">
            Category
          </Link>
          {userData?.access ? (
            <>
              <Link to="/profile" className={styles.navcontent}>
                Profile
              </Link>
              <Link className={styles.navcontent} href="/" onClick={logoutUser}>
                Sign Out
              </Link>
            </>
          ) : (
            <Link className={styles.navcontent} to="/login">
              Sign In
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Header;
