import { createSlice } from "@reduxjs/toolkit";
import { getAllBlogsApi } from "../../api/blogs";

export const slice = createSlice({
  name: "blog",
  initialState: {
    allBlog: [],
    allBlogLoading: false,
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    allBlogSuccess: (state, action) => {
      state.allBlog = action.payload;
      state.allBlogLoading = false;
      state.error.haveError = false;
    },
    allBlogFailed: (state, action) => {
      state.allBlog = [];
      state.allBlogLoading = false;
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
  },
});

export const selectAllBlog = (state) => state.blog.allBlog || [];
export const selectAllBlogLoading = (state) =>
  state.blog.allBlogLoading || false;
export const selectBlogErr = (state) => state.blog.error || {};

export const { allBlogSuccess, allBlogFailed } = slice.actions;

export const GetAllBlog = (dataObject) => async (dispatch) => {
  try {
    const response = await getAllBlogsApi(dataObject);
    dispatch(allBlogSuccess(response));
  } catch (err) {
    dispatch(allBlogFailed(err.toString()));
  }
};

export default slice.reducer;
