import { NoAuthGetRequest, getRequest, postRequest } from "./request";
const user = localStorage.getItem("user");
var userData = JSON.parse(user);

export function siteCreateApi(dataObject) {
  return postRequest({
    path: `sites/`,
    data: {
      url: dataObject?.url,
      company_name: dataObject?.company_name,
      category: dataObject?.category,
      description: dataObject?.description,
    },
  }).then((response) => {
    return response ? response : undefined;
  });
}
export function getSiteApi(dataObject) {
  if (userData?.id) {
    return getRequest({
      path: `sites/retrieve_site/?name=${dataObject.name}`,
    }).then((response) => {
      return response ? response : undefined;
    });
  } else {
    return NoAuthGetRequest({
      path: `sites/retrieve_site/?name=${dataObject.name}`,
    }).then((response) => {
      return response ? response : undefined;
    });
  }
}

export function getTrendingSiteApi(dataObject) {
  return NoAuthGetRequest({
    path: `sites/trending_sites/`,
  }).then((response) => {
    return response ? response : undefined;
  });
}

export function getSearchApi(dataObject) {
  return NoAuthGetRequest({
    path: `sites/search/?q=${dataObject.url}`,
  }).then((response) => {
    return response ? response : undefined;
  });
}
