import { createSlice } from "@reduxjs/toolkit";
import { getTrendingReviewApi } from "../../api/review";

export const slice = createSlice({
  name: "trendingReview",
  initialState: {
    trendingReviews: [],
    trendingReviewsLoading: false,
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    trendingReviewsSuccess: (state, action) => {
      state.trendingReviews = action.payload;
      state.trendingReviewsLoading = false;
      state.error.haveError = false;
    },
    trendingReviewsFailed: (state, action) => {
      state.trendingReviews = [];
      state.trendingReviewsLoading = false;
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
    cleartrendingReviews: (state, action) => {
      state.trendingReviews = [];
      state.trendingReviewsLoading = false;
      state.error = {
        message: "",
        haveError: false,
      };
    },
  },
});

export const selecttrendingReviews = (state) =>
  state.trendingReview.trendingReviews || [];
export const selecttrendingReviewsLoading = (state) =>
  state.trendingReview.trendingReviewsLoading || false;
export const selecttrendingReviewsErr = (state) =>
  state.trendingReview.error || {};

export const {
  trendingReviewsSuccess,
  trendingReviewsFailed,
  cleartrendingReviews,
} = slice.actions;

export const GettrendingReviews = (dataObject) => async (dispatch) => {
  try {
    const response = await getTrendingReviewApi(dataObject);
    if (response) {
      dispatch(trendingReviewsSuccess(response));
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    dispatch(trendingReviewsFailed(err.message));
  }
};

export const CleartrendingReviews = (dataObject) => async (dispatch) => {
  try {
    dispatch(cleartrendingReviews());
  } catch (err) {
    console.log(err);
  }
};

export default slice.reducer;
