import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

const Home = React.lazy(() => import("./pages/Home/Home"));
const Review = React.lazy(() => import("./pages/Review/Review"));
const Login = React.lazy(() => import("./pages/Login/Login"));
const Register = React.lazy(() => import("./pages/Register/Register"));
const EmailConfirm = React.lazy(() => import("./pages/Register/EmailConfirm"));
const Profile = React.lazy(() => import("./pages/Profile/Profile"));
const Blog = React.lazy(() => import("./pages/Blog/Blog"));
const SingleBlog = React.lazy(() => import("./pages/SingleBlog/SingleBlog"));
const CategoryDetail = React.lazy(() =>
  import("./pages/CategoryDetail/CategoryDetail")
);
const Category = React.lazy(() => import("./pages/Category/Category"));
const CreateReviewPage = React.lazy(() =>
  import("./pages/CreateReview/CreateReview")
);
const UpdateReviewPage = React.lazy(() =>
  import("./pages/UpdateReview/UpdateReview")
);
const CreateSite = React.lazy(() => import("./pages/Site/CreateSite"));
const PrivacyPolicy = React.lazy(() =>
  import("./pages/PrivacyPolicy/PrivacyPolicy")
);
const TermsAndConditions = React.lazy(() =>
  import("./pages/TermsAndCondition/TermsAndConditions")
);

function App() {
  // Uncomment and use PrivateRoute for routes that require authentication
  // const isLogin = () => {
  //   const user = localStorage.getItem("user");
  //   if (user) {
  //     const userData = JSON.parse(user);
  //     return !!userData.access;
  //   }
  //   return false;
  // };

  // const PrivateRoute = ({ element }) => {
  //   return isLogin() ? element : <Navigate to="/login" />;
  // };

  return (
    <Router>
      <div>
        <Header />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/create/site" element={<CreateSite />} />
            <Route
              path="/create/review/:site/:site_id"
              element={<CreateReviewPage />}
            />
            <Route
              path="/edit/review/:site/:site_id"
              element={<UpdateReviewPage />}
            />
            <Route path="/review/:site" element={<Review />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/category" element={<Category />} />
            <Route path="/category/:cat_name" element={<CategoryDetail />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<SingleBlog />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/confirm-email/:code" element={<EmailConfirm />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
