import { createSlice } from "@reduxjs/toolkit";
import { getAllCategoryApi, getTrendingCategoryApi } from "../../api/category";

export const slice = createSlice({
  name: "category",
  initialState: {
    allcategory: [],
    trendingCategory: [],
    allcategoryLoading: false,
    error: {
      message: "",
      haveError: false,
    },
    trendingCategoryLoading: false,
    trendingError: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    allcategorySuccess: (state, action) => {
      state.allcategory = action.payload;
      state.allcategoryLoading = false;
      state.error.haveError = false;
    },
    allcategoryFailed: (state, action) => {
      state.allcategory = [];
      state.allcategoryLoading = false;
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
    trendingCategorySuccess: (state, action) => {
      state.trendingCategory = action.payload;
      state.trendingCategoryLoading = false;
      state.trendingError.haveError = false;
    },
    trendingCategoryFailed: (state, action) => {
      state.trendingCategory = [];
      state.trendingCategoryLoading = false;
      state.trendingError = {
        message: action.payload,
        haveError: true,
      };
    },
  },
});

export const selectAllCategory = (state) => state.category.allcategory || [];
export const selectAllCategoryLoading = (state) =>
  state.category.allcategoryLoading || false;
export const selectcategoryErr = (state) => state.category.error || {};

export const selectTrendingCategory = (state) =>
  state.category.trendingCategory || [];
export const selectTrendingCategoryLoading = (state) =>
  state.category.trendingCategoryLoading || false;
export const selectTrendingCategoryErr = (state) =>
  state.category.trendingError || {};

export const {
  allcategorySuccess,
  allcategoryFailed,
  trendingCategorySuccess,
  trendingCategoryFailed,
} = slice.actions;

export const GetAllCategory = (dataObject) => async (dispatch) => {
  try {
    const response = await getAllCategoryApi(dataObject);
    dispatch(allcategorySuccess(response));
  } catch (err) {
    dispatch(allcategoryFailed(err.toString()));
  }
};

export const GetTrendingCategory = (dataObject) => async (dispatch) => {
  try {
    const response = await getTrendingCategoryApi(dataObject);
    dispatch(trendingCategorySuccess(response));
  } catch (err) {
    dispatch(trendingCategoryFailed(err.toString()));
  }
};

export default slice.reducer;
