import { jwtDecode } from "jwt-decode";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

async function handleResponse(response) {
  if (!response.ok) {
    console.error(`HTTP error! Status: ${response.status}`);
  }
  return response.json();
}

async function NoAuthPostRequest(request) {
  const url = `${BASE_URL}${request.path}`;
  const fetchData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request.data),
  };

  try {
    const response = await fetch(url, fetchData);
    return handleResponse(response);
  } catch (error) {
    console.error("API REQUEST FAILED", error);
  }
}

async function NoAuthGetRequest(request) {
  const url = `${BASE_URL}${request.path}`;
  const fetchData = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: request?.data && JSON.stringify(request.data),
  };

  try {
    const response = await fetch(url, fetchData);
    return handleResponse(response);
  } catch (error) {
    console.error("API REQUEST FAILED", error);
  }
}

async function refreshToken() {
  const user = localStorage.getItem("user");
  if (!user) throw new Error("No user data available in localStorage");

  const userData = JSON.parse(user);
  const { refresh } = userData;
  if (!refresh) throw new Error("No refresh token available");

  const url = `${BASE_URL}user/token/refresh/`;
  const fetchData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refresh }),
  };

  try {
    const response = await fetch(url, fetchData);
    const data = await handleResponse(response);

    const updatedUser = {
      ...userData,
      access: data.access,
      refresh: data.refresh,
    };
    localStorage.setItem("user", JSON.stringify(updatedUser));
    return data.access;
  } catch (error) {
    console.error("Token refresh failed", error);
    localStorage.removeItem("user");
  }
}

function isTokenExpired(token) {
  if (!token) return true;
  const decoded = jwtDecode(token);
  const now = Date.now().valueOf() / 1000;
  return decoded.exp < now;
}

async function getAccessToken() {
  const user = localStorage.getItem("user");
  if (!user) throw new Error("No user data available in localStorage");

  const userData = JSON.parse(user);
  let { access } = userData;
  if (!access || isTokenExpired(access)) {
    access = await refreshToken();
  }
  return access;
}

async function makeRequest(request, method) {
  const accessToken = await getAccessToken();
  const url = `${BASE_URL}${request.path}`;
  const fetchData = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: request.data ? JSON.stringify(request.data) : undefined,
  };

  try {
    const response = await fetch(url, fetchData);
    return handleResponse(response);
  } catch (error) {
    console.error("API REQUEST FAILED", error);
  }
}

async function postRequest(request) {
  return makeRequest(request, "POST");
}

async function getRequest(request) {
  return makeRequest(request, "GET");
}

async function deleteRequest(request) {
  return makeRequest(request, "DELETE");
}

async function putRequest(request) {
  return makeRequest(request, "PUT");
}

export {
  postRequest,
  getRequest,
  putRequest,
  NoAuthPostRequest,
  NoAuthGetRequest,
  deleteRequest,
};
