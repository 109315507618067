import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

import insta from "../../images/insta.svg";
import fb from "../../images/fb.svg";
import twitter from "../../images/twitter.svg";

const Footer = () => {
  return (
    <nav className={styles.navbar}>
      <div className={styles.footer_bottom}>
        <div>
          <Link to="/terms-and-conditions">Terms and conditions</Link>
        </div>

        <div>
          <Link to="/privacy-policy">Privacy policy</Link>
        </div>
      </div>

      <div className={styles.footer_top}>
        <p>
          For enquiries :
          <a href="mailto:wyrloop@gmail.com"> wyrloop@gmail.com</a>
        </p>
        <p>Copyright &#169; {new Date().getFullYear()} WYRloop</p>
      </div>

      <div className={styles.footer_top}>
        {/* icon8 -icons */}
        <div>
          <a
            href="https://x.com/wyrloop/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} alt="twitterIcon" className={styles.icon} />
          </a>
          <a
            href="https://www.instagram.com/wyrloop/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={insta} alt="instaIcon" className={styles.icon} />
          </a>
          <a
            href="https://www.facebook.com/wyrloop/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={fb} alt="fbIcon" className={styles.icon} />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Footer;
