import { createSlice } from "@reduxjs/toolkit";
import { getSearchApi, getTrendingSiteApi } from "../../api/site";

export const slice = createSlice({
  name: "site",
  initialState: {
    allSite: [],
    searchSite: [],
    allSiteLoading: false,
    searchSiteLoading: false,
    error: {
      message: "",
      haveError: false,
    },
    searchError: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    allSiteSuccess: (state, action) => {
      state.allSite = action.payload;
      state.allSiteLoading = false;
      state.error.haveError = false;
    },
    allSiteFailed: (state, action) => {
      state.allSite = [];
      state.allSiteLoading = false;
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
    searchSiteSuccess: (state, action) => {
      state.searchSite = action.payload;
      state.searchSiteLoading = false;
      state.searchError.haveError = false;
    },
    searchSiteFailed: (state, action) => {
      state.searchSite = [];
      state.searchSiteLoading = false;
      state.searchError = {
        message: action.payload,
        haveError: true,
      };
    },
    searchSiteClear: (state, action) => {
      state.searchSite = [];
      state.searchSiteLoading = false;
      state.searchError = {
        message: "",
        haveError: false,
      };
    },
  },
});

export const selectAllSite = (state) => state.site.allSite || [];
export const selectSearchSite = (state) => state.site.searchSite || [];
export const selectAllSiteLoading = (state) =>
  state.site.allSiteLoading || false;
export const selectSearchSiteLoading = (state) =>
  state.site.searchSiteLoading || false;
export const selectSiteErr = (state) => state.site.error || {};

export const {
  allSiteSuccess,
  allSiteFailed,
  searchSiteFailed,
  searchSiteSuccess,
  searchSiteClear,
} = slice.actions;

export const GetAllSite = (dataObject) => async (dispatch) => {
  try {
    const response = await getTrendingSiteApi(dataObject);
    dispatch(allSiteSuccess(response));
  } catch (err) {
    dispatch(allSiteFailed(err.toString()));
  }
};

export const GetSearchSite = (dataObject) => async (dispatch) => {
  try {
    const response = await getSearchApi(dataObject);
    dispatch(searchSiteSuccess(response));
  } catch (err) {
    dispatch(searchSiteFailed(err.message));
  }
};

export const ClearSearchSite = (dataObject) => async (dispatch) => {
  try {
    dispatch(searchSiteClear());
  } catch (err) {
    dispatch(searchSiteFailed(err.message));
  }
};

export default slice.reducer;
