import { createSlice } from "@reduxjs/toolkit";
import { updateReviewApi } from "../../api/review";
import { getSiteApi } from "../../api/site";

export const slice = createSlice({
  name: "review",
  initialState: {
    info: {},
    loading: false,
    error: {
      message: "",
      haveError: false,
      updateFailed: false,
    },
  },
  reducers: {
    reviewSuccess: (state, action) => {
      state.info = action.payload;
      state.loading = false;
      state.error = {
        message: "",
        haveError: false,
        updateFailed: false,
      };
    },
    reviewFailed: (state, action) => {
      state.info = {};
      state.loading = false;
      state.error = {
        message: action.payload,
        haveError: true,
        updateFailed: false,
      };
    },
    updateFailed: (state, action) => {
      state.info = {};
      state.loading = false;
      state.error = {
        message: action.payload,
        haveError: true,
        updateFailed: true,
      };
    },
    clearInfo: (state, action) => {
      state.info = {};
      state.loading = false;
      state.error = {
        message: "",
        haveError: false,
        updateFailed: false,
      };
    },
  },
});

export const selectReview = (state) => state.review.info || {};
export const selectReviewLoading = (state) => state.review.loading || false;
export const selectReviewErr = (state) => state.review.error || {};

export const { reviewSuccess, reviewFailed, clearInfo, updateFailed } =
  slice.actions;

export const UpdateReview = (dataObject) => async (dispatch) => {
  try {
    const response = await updateReviewApi(dataObject);
    if (response?.site?.id) {
      dispatch(reviewSuccess(response));
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    dispatch(updateFailed(err.message));
  }
};

export const GetSiteInfo = (dataObject) => async (dispatch) => {
  try {
    const response = await getSiteApi(dataObject);
    if (response?.site?.id) {
      dispatch(reviewSuccess(response));
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    dispatch(reviewFailed(err.message));
  }
};

export const ClearReview = () => (dispatch) => {
  try {
    dispatch(clearInfo());
  } catch (err) {
    console.log(err);
    // dispatch(reviewFailed(err.message));
  }
};

export default slice.reducer;
