import { NoAuthGetRequest } from "./request";

export function getAllCategoryApi() {
  return NoAuthGetRequest({
    path: `categories/`,
  }).then((response) => {
    return response ? response : null;
  });
}

export function getTrendingCategoryApi() {
  return NoAuthGetRequest({
    path: `categories/trending_categories/`,
  }).then((response) => {
    return response ? response : null;
  });
}

export function getSitesForCategoryApi(dataObject) {
  return NoAuthGetRequest({
    path: `sites/sites_by_category/?category_name=${dataObject.category}`,
  }).then((response) => {
    return response ? response : null;
  });
}
