import { createSlice } from "@reduxjs/toolkit";
import {
  createReviewApi,
  deleteReviewApi,
  getUserReviewApi,
  updateReviewApi,
} from "../../api/review";

export const slice = createSlice({
  name: "userReview",
  initialState: {
    review: {},
    loading: false,
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    reviewSuccess: (state, action) => {
      state.review = action.payload;
      state.loading = false;
      state.error = {
        message: "",
        haveError: false,
      };
    },
    reviewFailed: (state, action) => {
      state.review = {};
      state.loading = false;
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
    clearInfo: (state, action) => {
      state.review = {};
      state.loading = false;
      state.error = {
        message: "",
        haveError: false,
      };
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const selectReview = (state) => state.userReview.review || {};
export const selectReviewLoading = (state) => state.userReview.loading || false;
export const selectReviewErr = (state) => state.userReview.error || {};

export const { reviewSuccess, reviewFailed, clearInfo, loading } =
  slice.actions;

export const SetLoading = (value) => (dispatch) => {
  dispatch(loading(value));
};

export const GetReview = (dataObject) => async (dispatch) => {
  try {
    SetLoading(true);
    const response = await getUserReviewApi(dataObject);
    if (response?.review?.id) {
      dispatch(reviewSuccess(response));
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    dispatch(reviewFailed(err.message));
  }
};

export const CreateReview = (dataObject) => async (dispatch) => {
  try {
    SetLoading(true);
    const response = await createReviewApi(dataObject);
    if (response?.review?.id) {
      dispatch(reviewSuccess(response));
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    dispatch(reviewFailed(err.message));
  }
};

export const UpdateReview = (dataObject) => async (dispatch) => {
  try {
    SetLoading(true);
    const response = await updateReviewApi(dataObject);
    if (response?.review?.id) {
      dispatch(reviewSuccess(response));
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    dispatch(reviewFailed(err.message));
  }
};

export const DeleteReview = (dataObject) => async (dispatch) => {
  try {
    SetLoading(true);
    const response = await deleteReviewApi(dataObject);
    if (response) {
      dispatch(reviewSuccess(response));
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    dispatch(reviewFailed(err.message));
  }
};
export const ClearReview = () => (dispatch) => {
  try {
    dispatch(clearInfo());
  } catch (err) {
    console.log(err);
    // dispatch(reviewFailed(err.message));
  }
};

export default slice.reducer;
