import { createSlice } from "@reduxjs/toolkit";
import { getSitesForCategoryApi } from "../../api/category";

export const slice = createSlice({
  name: "categorySite",
  initialState: {
    categorySite: [],
    categorySiteLoading: false,
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    categorySiteSuccess: (state, action) => {
      state.categorySite = action.payload;
      state.categorySiteLoading = false;
      state.error.haveError = false;
    },
    categorySiteFailed: (state, action) => {
      state.categorySite = [];
      state.categorySiteLoading = false;
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },

    siteClear: (state, action) => {
      state.categorySite = [];
      state.categorySiteLoading = false;
      state.error = {
        message: "",
        haveError: false,
      };
    },
  },
});

export const selectCategorySite = (state) =>
  state.categorySite.categorySite || [];
export const selectCategorySiteLoading = (state) =>
  state.categorySite.categorySiteLoading || false;
export const selectSiteErr = (state) => state.categorySite.error || {};

export const { categorySiteSuccess, categorySiteFailed, siteClear } =
  slice.actions;

export const GetCategorySite = (dataObject) => async (dispatch) => {
  try {
    const response = await getSitesForCategoryApi(dataObject);
    dispatch(categorySiteSuccess(response));
  } catch (err) {
    dispatch(categorySiteFailed(err.toString()));
  }
};

export const ClearCategorySite = (dataObject) => async (dispatch) => {
  try {
    dispatch(siteClear());
  } catch (err) {
    dispatch(categorySiteFailed(err.message));
  }
};

export default slice.reducer;
