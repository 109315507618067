import { NoAuthGetRequest } from "./request";

export function getAllBlogsApi(dataObject) {
  return NoAuthGetRequest({
    path: `blog/posts/`,
  }).then((response) => {
    return response ? response : undefined;
  });
}

export function getBlogsApi(dataObject) {
  return NoAuthGetRequest({
    path: `blog/posts/${dataObject.slug}`,
  }).then((response) => {
    return response ? response : undefined;
  });
}
