import { createSlice } from "@reduxjs/toolkit";
import { getUserReviewsApi } from "../../api/user";

export const slice = createSlice({
  name: "profileReview",
  initialState: {
    userReviews: [],
    userReviewsLoading: false,
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    userReviewsSuccess: (state, action) => {
      state.userReviews = action.payload;
      state.userReviewsLoading = false;
      state.error.haveError = false;
    },
    userReviewsFailed: (state, action) => {
      state.userReviews = [];
      state.userReviewsLoading = false;
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
    clearUserReviews: (state, action) => {
      state.userReviews = [];
      state.userReviewsLoading = false;
      state.error = {
        message: "",
        haveError: false,
      };
    },
  },
});

export const selectUserReviews = (state) =>
  state.profileReview.userReviews || [];
export const selectUserReviewsLoading = (state) =>
  state.profileReview.userReviewsLoading || false;
export const selectUserReviewsErr = (state) => state.profileReview.error || {};

export const { userReviewsSuccess, userReviewsFailed, clearUserReviews } =
  slice.actions;

export const GetUserReviews = (dataObject) => async (dispatch) => {
  try {
    const response = await getUserReviewsApi(dataObject);
    if (response) {
      dispatch(userReviewsSuccess(response));
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    dispatch(userReviewsFailed(err.message));
  }
};

export const ClearUserReviews = (dataObject) => async (dispatch) => {
  try {
    dispatch(clearUserReviews());
  } catch (err) {
    console.log(err);
  }
};

export default slice.reducer;
