import { createSlice } from "@reduxjs/toolkit";
import { getUserSitesApi } from "../../api/user";

export const slice = createSlice({
  name: "profileSite",
  initialState: {
    userSites: [],
    userSitesLoading: false,
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    userSitesSuccess: (state, action) => {
      state.userSites = action.payload;
      state.userSitesLoading = false;
      state.error.haveError = false;
    },
    userSitesFailed: (state, action) => {
      state.userSites = [];
      state.userSitesLoading = false;
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
    clearUserSites: (state, action) => {
      state.userSites = [];
      state.userSitesLoading = false;
      state.error = {
        message: "",
        haveError: false,
      };
    },
  },
});

export const selectUserSites = (state) => state.profileSite.userSites || [];
export const selectUserSitesLoading = (state) =>
  state.profileSite.userSitesLoading || false;
export const selectUserSitesErr = (state) => state.profileSite.error || {};

export const { userSitesSuccess, userSitesFailed, clearUserSites } =
  slice.actions;

export const GetUserSites = (dataObject) => async (dispatch) => {
  try {
    const response = await getUserSitesApi(dataObject);
    if (response) {
      dispatch(userSitesSuccess(response));
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    dispatch(userSitesFailed(err.message));
  }
};

export const ClearUserSites = (dataObject) => async (dispatch) => {
  try {
    dispatch(clearUserSites());
  } catch (err) {
    console.log(err);
  }
};

export default slice.reducer;
