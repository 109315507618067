import { createSlice } from "@reduxjs/toolkit";
import { siteCreateApi } from "../../api/site";

export const slice = createSlice({
  name: "userSite",
  initialState: {
    site: {},
    loading: false,
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    siteSuccess: (state, action) => {
      state.site = action.payload;
      state.loading = false;
      state.error = {
        message: "",
        haveError: false,
      };
    },
    siteFailed: (state, action) => {
      state.site = {};
      state.loading = false;
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
    clearInfo: (state, action) => {
      state.site = {};
      state.loading = false;
      state.error = {
        message: "",
        haveError: false,
      };
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const selectSite = (state) => state.userSite.site || {};
export const selectSiteLoading = (state) => state.userSite.loading || false;
export const selectSiteErr = (state) => state.userSite.error || {};

export const { siteSuccess, siteFailed, clearInfo, loading } = slice.actions;

export const SetLoading = (value) => (dispatch) => {
  dispatch(loading(value));
};

export const CreateSiteAPI = (dataObject) => async (dispatch) => {
  try {
    SetLoading(true);
    const response = await siteCreateApi(dataObject);
    if (response?.site?.id) {
      dispatch(siteSuccess(response));
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    dispatch(siteFailed(err.message));
  }
};

export const Clearsite = () => (dispatch) => {
  try {
    dispatch(clearInfo());
  } catch (err) {
    console.log(err);
    // dispatch(siteFailed(err.message));
  }
};

export default slice.reducer;
