import { createSlice } from "@reduxjs/toolkit";
import {
  getProfileApi,
  updatePasswordApi,
  updateProfileApi,
} from "../../api/user";

export const slice = createSlice({
  name: "profile",
  initialState: {
    profileInfo: {},
    profileInfoLoading: false,
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    profileInfoSuccess: (state, action) => {
      state.profileInfo = action.payload;
      state.profileInfoLoading = false;
      state.error.haveError = false;
    },
    profileInfoFailed: (state, action) => {
      state.profileInfo = {};
      state.profileInfoLoading = false;
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
    clearProfileInfo: (state, action) => {
      state.profileInfo = {};
      state.profileInfoLoading = false;
      state.error = {
        message: "",
        haveError: false,
      };
    },
  },
});

export const selectProfileInfo = (state) => state.profile.profileInfo || {};
export const selectProfileInfoLoading = (state) =>
  state.profile.profileInfoLoading || false;
export const selectSiteErr = (state) => state.profile.error || {};

export const { profileInfoSuccess, profileInfoFailed, clearProfileInfo } =
  slice.actions;

export const GetProfileInfo = (dataObject) => async (dispatch) => {
  try {
    const response = await getProfileApi(dataObject);
    if (response?.user?.id) {
      dispatch(profileInfoSuccess(response));
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    dispatch(profileInfoFailed(err.message));
  }
};

export const UpdateProfileInfo = (dataObject) => async (dispatch) => {
  try {
    const response = await updateProfileApi(dataObject);
    if (response?.userinfo?.id) {
      dispatch(profileInfoSuccess(response));
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    dispatch(profileInfoFailed(err.message));
  }
};

export const UpdatePassword = (dataObject) => async (dispatch) => {
  try {
    const response = await updatePasswordApi(dataObject);
    if (response?.status === "password_updated") {
      console.log("Password Updated");
    } else {
      throw new Error(JSON.stringify(response));
    }
  } catch (err) {
    console.log("Password Updation Failed");
  }
};

export const ClearProfileInfo = (dataObject) => async (dispatch) => {
  try {
    dispatch(clearProfileInfo());
  } catch (err) {
    console.log(err);
  }
};

export default slice.reducer;
