import {
  NoAuthPostRequest,
  getRequest,
  putRequest,
  NoAuthGetRequest,
} from "./request";

export function RegisterUserApi(dataObject) {
  return NoAuthPostRequest({
    path: `user/register/`,
    data: {
      email: dataObject.email,
      password: dataObject.password,
      username: dataObject.username,
    },
  }).then((response) => {
    return response ? response : undefined;
  });
}

export function EmailConfirmApi(dataObject) {
  return NoAuthGetRequest({
    path: `user/confirm-email/${dataObject}/`,
  }).then((response) => {
    return response ? response : undefined;
  });
}
export function ResendConfirmationEmailApi(dataObject) {
  return NoAuthPostRequest({
    path: `user/resend-confirmation/`,
    data: dataObject,
  }).then((response) => {
    return response ? response : undefined;
  });
}

export function LoginUserApi(dataObject) {
  return NoAuthPostRequest({
    path: `user/login/`,
    data: {
      email: dataObject.email,
      password: dataObject.password,
    },
  }).then((response) => {
    return response ? response : null;
  });
}

export function getProfileApi(dataObject) {
  return getRequest({
    path: `user/profile/`,
  }).then((response) => {
    return response ? response : undefined;
  });
}

export function updateProfileApi(dataObject) {
  return putRequest({
    path: `user/profile/userinfo/`,
    data: {
      date_of_birth: dataObject.date_of_birth,
      first_name: dataObject.first_name,
      last_name: dataObject.last_name,
      sex: dataObject.sex,
    },
  }).then((response) => {
    return response ? response : undefined;
  });
}

export function updatePasswordApi(dataObject) {
  return putRequest({
    path: `user/profile/password/`,
    data: {
      old_password: dataObject.oldPassword,
      new_password: dataObject.newPassword,
    },
  }).then((response) => {
    return response ? response : undefined;
  });
}

export function getUserReviewsApi(dataObject) {
  return getRequest({
    path: `review/my_reviews/`,
  }).then((response) => {
    return response ? response : undefined;
  });
}

export function getUserSitesApi(dataObject) {
  return getRequest({
    path: `sites/my_sites/`,
  }).then((response) => {
    return response ? response : undefined;
  });
}
